import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>Kampaania reeglid</h2>
      <ol>
        <li>
          Kampaania “Ärka koos Fazeriga” on alates 01.10.2024 kuni 31.10.2024
          toimuv tarbijamäng (edaspidi kampaania), mis leiab aset kõigis Eesti
          Vabariigi territooriumil asuvates kauplustes, kus kampaanias osalevaid
          tooteid müüakse.
        </li>
        <li>
          Kampaania ja auhinnad korraldab:{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5. korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
        </li>
      </ol>
      <h3>OSALEMINE ja AUHINNAD</h3>
      <ol>
        <li>
          Kampaania info on kättesaadav aadressil{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a>
        </li>
        <li>
          Kampaania auhinnaloosis osalemiseks tuleb osta ükskõik millisest
          kauplusest üle Eesti vähemalt üks kampaanias osalev Fazer brändi toode
          ja registreerida ostutšeki number veebilehel{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a> ajavahemikus 01.10 –
          31.10.2024 (k.a).
        </li>
        <li>
          3. Auhinnafondi koguväärtus on 5550 eurot. Auhinnafondis on kokku 100
          auhinda:{' '}
          <span className="block-span">50x Tefal Röster, Sense 2S, valge</span>{' '}
          <span className="block-span">
            20x Tefal võileivagrill, Snack Collection, roostevaba teras
          </span>{' '}
          <span className="block-span">30x Fazer toodete kinkekott</span>
        </li>
        <li>
          Kampaanias osaleja peab registreeritud ostutšeki alles hoidma
          kampaania lõpuni, et esitada see võimaliku võidu kättesaamiseks.
        </li>
        <li>Ühte ostutšekki saab kampaania jooksul registreerida ühe korra.</li>
        <li>
          Osaleja võib teha mitu registreerimist, kui ostab kampaanias osalevaid
          tooteid mitu korda ja registreerib{' '}
          <span className="bold">iga kord uue ostutšeki numbri.</span>
        </li>
        <li>Kampaanias osalevad tooted on:</li>
        <table>
          <thead>
            <tr>
              <td>EAN</td>
              <td>Toote nimetus</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4740103011256</td>
              <td>Fazeri Juuretise Röst 450g</td>
            </tr>
            <tr>
              <td>4740103010563</td>
              <td>Fazeri Juuretisesai 500g</td>
            </tr>
            <tr>
              <td>4740103011263</td>
              <td>Fazer Rukki Juuretise röst 450g</td>
            </tr>
            <tr>
              <td>4740103022665</td>
              <td>Fazeri Juuretise peenleib 500g</td>
            </tr>
            <tr>
              <td>4740103011188</td>
              <td>Fazer Seemneröst 450g</td>
            </tr>
            <tr>
              <td>4740103011164</td>
              <td>Fazer Keefiriröst 450g</td>
            </tr>
            <tr>
              <td>4740103011140</td>
              <td>Fazer 5-viljaröst täisterahelvestega 450g</td>
            </tr>
            <tr>
              <td>4740103011300</td>
              <td>Fazer Seemnetega röst 500g</td>
            </tr>
          </tbody>
        </table>
        <li>
          Loosimised viiakse läbi loosimistarkvara stat.messenger.ee abil, mis
          võimaldab määrata loosimise täpse perioodi, auhindade kogused ning
          valib võitjad määratud perioodi jooksul registreerunud osalejate seast
          juhuse alusel. Auhinna loosimised toimuvad iga nädal, järgnevatel
          kuupäevadel:
          <ul>
            <li>
              08.10.24 osalevad kõik registreeringud, mis on tehtud vahemikus
              01.10 kl 00:00:00 – 07.10 kl 23:59:59,
              <span className="block-span padded">
                10x Tefal Röster, Sense 2S, valge
              </span>
              <span className="block-span padded">
                4x Tefal võileivagrill, Snack Collection, roostevaba teras
              </span>
              <span className="block-span padded">
                6x Fazer toodete kinkekott
              </span>
            </li>
            <li>
              15.10.24 osalevad kõik registreeringud, mis on tehtud vahemikus
              08.10 kl 00:00:00 – 14.10 kl 23:59:59,
              <span className="block-span padded">
                10x Tefal Röster, Sense 2S, valge
              </span>
              <span className="block-span padded">
                4x Tefal võileivagrill, Snack Collection, roostevaba teras
              </span>
              <span className="block-span padded">
                6x Fazer toodete kinkekott
              </span>
            </li>
            <li>
              22.10.24 osalevad kõik registreeringud, mis on tehtud vahemikus
              15.10 kl 00:00:00 – 21.10 kl 23:59:59,
              <span className="block-span padded">
                10x Tefal Röster, Sense 2S, valge
              </span>
              <span className="block-span padded">
                4x Tefal võileivagrill, Snack Collection, roostevaba teras
              </span>
              <span className="block-span padded">
                6x Fazer toodete kinkekott
              </span>
            </li>
            <li>
              29.10.24 osalevad kõik registreeringud, mis on tehtud vahemikus
              22.10 kl 00:00:00 – 28.10 kl 23:59:59,
              <span className="block-span padded">
                10x Tefal Röster, Sense 2S, valge
              </span>
              <span className="block-span padded">
                4x Tefal võileivagrill, Snack Collection, roostevaba teras
              </span>
              <span className="block-span padded">
                6x Fazer toodete kinkekott
              </span>
            </li>
            <li>
              04.11.24 osalevad kõik registreeringud, mis on tehtud vahemikus
              01.10 kl 00:00:00 – 31.10 kl 23:59:59,
              <span className="block-span padded">
                10x Tefal Röster, Sense 2S, valge
              </span>
              <span className="block-span padded">
                4x Tefal võileivagrill, Snack Collection, roostevaba teras
              </span>
              <span className="block-span padded">
                6x Fazer toodete kinkekott
              </span>
            </li>
          </ul>
        </li>
        <li>
          Loosimisel osalevad kõik kampaania perioodil vastavalt kampaania
          tingimustele üle Eesti kauplustes oste sooritanud kliendid.{' '}
        </li>
        <li>
          Võitjaga võetakse korraldaja esindaja poolt ühendust
          registreerimisvormis märgitud e-posti või telefoni teel võidu
          kinnitamiseks ning auhinna kättetoimetamise täpsustamiseks. Võitja
          nimi avaldatakse veebilehel{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a> hiljemalt 3 tööpäeva
          jooksul pärast loosimise kuupäeva.{' '}
        </li>
        <li>
          Juhul, kui võitjaga ei ole õnnestunud ühendust saada hiljemalt 5
          tööpäeva jooksul on korraldajal õigus auhinda mitte väljastada ja
          loosida see välja uuele võitjale.
        </li>
        <h3>MUUD TINGIMUSED</h3>
        <li>
          Võidetud auhinna kättesaamisega seotud kulutusi ei kompenseerita,
          saadud auhinda ei asendata teise auhinnaga ega hüvitata selle
          maksumust rahas. Kampaania korraldaja võtab vastutuse kõigi
          auhindadega seotud seadusjärgsete maksude tasumise eest.
        </li>
        <li>
          Auhinna kättesaamiseks peab võitja esitama oma isikut tõendava
          dokumendi ning võidu toonud ostutšeki.
        </li>
        <li>
          Kampaanias ei saa osaleda selle korraldamisega seotud inimesed ja
          nende pereliikmed.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui auhinda
          eeskirjade mittejärgimise tõttu välja ei anta, kui kampaanias osaleja
          esitatud andmed on väärad või ebatäpsed või kui võitjatega ei ole
          võimalik ühendust võtta kampaania korraldajatest mittesõltuvatel
          põhjustel.
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadustele. Vääramatu jõu (force majeure) ilmnemisel on
          korraldajal õigus tarbijamäng ühepoolselt katkestada, teatades sellest
          meedia vahendusel.{' '}
        </li>
        <li>
          Kõik pretensioonid seoses kampaania korraldamise ja/või läbiviimisega
          tuleb saata kirjalikult kampaania korraldaja asukohta:{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5. korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a> .
          Laekunud pretensioonidele vastatakse kirjalikult 2 nädala jooksul.
          Pretensioone saab esitada kuni 01.04.2024.
        </li>
        <li>
          Lisateavet kampaania kohta saab tööpäevadel{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5. korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a>
        </li>
        <h3>ANDMETE TÖÖTLEMINE</h3>
        <li>
          Isikuandmete vastutav töötleja on{' '}
          <span className="bold">Fazer Eesti OÜ</span>, Pärnu mnt 158/1, 11317
          Tallinn. Kampaanias osalejatel on õigus saada lisainfot Fazer Eesti OÜ
          poolt isikuandmete töötlemise kohta. Selleks tutvu lähemalt Fazer
          Eesti OÜ privaatsustingimustega siin:{' '}
          <a href="https://www.fazergroup.com/privacy/">
            https://www.fazergroup.com/privacy/
          </a>{' '}
        </li>
        <li>Võitjate nimed on veebilehel üleval kuni 29.11.2024.</li>
        <li>Kogutud andmeid säilitatakse kuni 06.11.2024.</li>
      </ol>
    </div>
  );
};

export default Et;
