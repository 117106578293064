import React, { ReactElement } from 'react';
import './Rules.scss';
import Et from './Et';
import En from './En';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

interface Props {
  rulesModalActive: boolean;
  setRulesModalActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const Rules: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  let RuleSet = Et;
  switch (getLanguage()) {
    case 'et':
      RuleSet = Et;
      break;
    case 'en':
      RuleSet = En;
      break;
    default:
      RuleSet = Et;
      break;
  }

  return (
    <div
      id="rules-modal"
      className={props.rulesModalActive ? 'modal is-active' : 'modal'}
    >
      <div className="modal-background"></div>
      <div className="modal-content">
        <RuleSet />
        <div className="close-btn-wrapper">
          <button
            className="button"
            onClick={() => props.setRulesModalActive(!props.rulesModalActive)}
          >
            {t('close')}
          </button>
        </div>
      </div>
      <div></div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => props.setRulesModalActive(!props.rulesModalActive)}
      ></button>
    </div>
  );
};

export default Rules;
