import React, { ReactElement } from 'react';
import './Thanks.scss';
import { Link } from 'react-router-dom';

const Thanks: React.FC = (): ReactElement => {
  return (
    <div className="Thanks">
      <Link to="/" className="close-button">
        &times;
      </Link>
      <h1>Täname osalemast!</h1>
      {/* <p>{t('thanks subheader')}</p> */}
    </div>
  );
};

export default Thanks;
